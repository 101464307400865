import {
  ButtonWrapper,
  CircleIconButton,
  Flag,
  ImageWrapper,
} from "@anthology/shared/src/components";
import { MediaPlayerId } from "@anthology/shared/src/enums";
import { useCssBreakpoint, useImageColours } from "@anthology/shared/src/hooks";
import {
  Alert,
  Box,
  Link,
  Skeleton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isAfter, isBefore } from "date-fns";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { BsYoutube } from "react-icons/bs";
import {
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaSnapchat,
  FaSoundcloud,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import {
  ClickEventVm,
  LinkVm,
  MediaPlayerVm,
  SelectListVm,
  SlinkyVm,
  useGetApiSlinkyResourceLanguagesQuery,
  useGetApiSlinkyResourceSocialMediaPlatformsQuery,
  useGetApiSlinkyResourceTerritoriesQuery,
} from "../../api/slinkyApi";
import {
  Destinations,
  MediaPlayerPosition,
  Platforms,
} from "../../api/types/autoEnum";
import {
  defaultBaseUrl,
  defaultErrorImage,
  slinkyDestinationFallBackImg,
} from "../../constants/urls";
import MiniAudioPlayer from "../mini-audio-player/MiniAudioPlayer";
import DolbyImmersiveIcon from "./DolbyImmersiveIcon";
import style from "./RenderSlinky.module.scss";
import { SlinkyLocalStorageNames } from "./consts";
import { handlePixelEvent, handlePixels, PixelEventType } from "./handlePixels";
import { getRedirectUrl } from "./presave";
import SlinkyCountDown, {
  useSlinkyCountdown,
} from "./slinkyCountdown/SlinkyCountdown";
import {
  checkPrivateMode,
  formatSpotifyUrl,
  formatYoutubeUrl,
  getLinksToShow,
  getLiveTime,
  getMobileDeviceOs,
  getSlinkyBgSxProps,
  getSlinkyFanPreferences,
  MobileDeviceOs,
} from "./slinkyHelpers";
import { SlinkyManagePermissions } from "./slinkyManagePreferences/SlinkyManagePermissions";
import {
  SlinkyPreSaveDialog,
  SlinkyPresaveProps,
} from "./slinkyPreSaveDialog/SlinkyPreSaveDialog";
import SlinkyTerritoryLanguageDialog, {
  TerritoryLanguage,
} from "./slinkyTerritoryLanguageDialog/SlinkyTerritoryLanguageDialog";
import SlinkySpotifyPlayer from "./spotifyPlayer/SlinkySpotifyPlayer";
import useIsMobile from "./useIsMobile";
import { SlinkyCta, useSlinkyTerritoryCta } from "./useSlinkyTerritoryCta";
// import dynamic from "next/dynamic";

// const SpotifyPlayer = dynamic(
//   () => import("./spotifyPlayer/SlinkySpotifyPlayer"),
//   {
//     ssr: false,
//   }
// );

interface PreSaveStateVm {
  guid: string;
  slinkyUrl: string;
  destinationId: Destinations;
  ipterritoryIso3code: string;
  utcminuteOffSet?: number;
  slinkyDestinationLinkId?: number;
}

export type RenderSlinkyProps = {
  slinky: SlinkyVm;
  isPreview?: boolean;
  forceMobile?: boolean;
  previewTerritoryId?: number;
  previewDate?: Date;
  isLoading?: boolean;
  handleInteraction?: {
    onClick: (link: LinkVm, cta: SlinkyCta, isClickThrough: boolean) => void;
    onPlay?: (
      mediaPlayerId: number,
      formatId?: number,
      assetId?: number,
      formatAssetId?: number
    ) => void;
  };
  languages?: SelectListVm[];
  territories?: SelectListVm[];
  landingUid?: string;
  correlationId?: string;
};

export type RenderSlinkyInnerProps = {
  territories?: SelectListVm[];
  languages?: SelectListVm[];
} & RenderSlinkyProps;

export default function RenderSlinky({
  slinky,
  isPreview,
  forceMobile,
  previewTerritoryId,
  previewDate,
  isLoading,
  handleInteraction,
  languages: languagesNext,
  territories: territoriesNext,
  landingUid,
  correlationId,
}: RenderSlinkyProps) {
  const { data: territoriesData } = useGetApiSlinkyResourceTerritoriesQuery(
    undefined,
    { skip: !!territoriesNext }
  );
  const { data: languagesData, isLoading: isLoadingLanguages } =
    useGetApiSlinkyResourceLanguagesQuery(undefined, { skip: !!languagesNext });

  const territories = territoriesData || territoriesNext;
  const languages = languagesData || languagesNext;

  return (
    <RenderSlinkyInner
      {...{
        slinky,
        territories,
        isPreview,
        forceMobile,
        previewTerritoryId,
        previewDate,
        languages,
        handleInteraction,
        landingUid,
        correlationId,
      }}
      isLoading={isLoading || isLoadingLanguages}
    />
  );
}

export interface Consent {
  hasConsented: boolean;
  consentTimestamp: string;
  // permissions: {
  //   analytics: boolean;
  //   advertising: boolean;
  // };
}

function RenderSlinkyInner({
  slinky,
  isPreview,
  forceMobile,
  previewTerritoryId,
  previewDate,
  isLoading,
  territories,
  languages,
  handleInteraction,
  landingUid,
  correlationId,
}: RenderSlinkyInnerProps) {
  const isMobile = useIsMobile();
  const os = getMobileDeviceOs();

  // Date
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });
  const viewDate = previewDate || currentDateTime;

  const imageUrl = isLoading
    ? defaultErrorImage
    : slinky?.lookAndFeel?.coverArtImageUrl;
  const getPlayer = (platformId: number): MediaPlayerVm | null | undefined => {
    const player =
      slinky?.mediaPlayers &&
      slinky?.mediaPlayers.find(
        (item) => item.platformLinkId === platformId && item.isActive
      );
    if (!player) return null;
    if (!player?.displayStartDateTime && !player?.displayEndDateTime)
      return player;
    const { displayStartDateTime, displayEndDateTime } = player;
    // const startDate =
    //   player?.displayStartDateTime && new Date(player?.displayStartDateTime);
    // const endDate =
    //   player?.displayEndDateTime && new Date(player?.displayEndDateTime);
    const liveStartDate =
      displayStartDateTime &&
      getLiveTime(displayStartDateTime, player.displayDateIsUtc);
    const liveEndDate =
      displayEndDateTime &&
      getLiveTime(displayEndDateTime, player.displayDateIsUtc);
    const startDateMatch = !liveStartDate || isAfter(viewDate, liveStartDate);
    const endDateMatch = !liveEndDate || isBefore(viewDate, liveEndDate);
    return startDateMatch && endDateMatch ? player : null;
  };

  // Hardcoded youtube and spotify for now - dynamic list in future?
  const youTubeUrl = getPlayer(MediaPlayerId.YouTube)?.mediaPlayerUrl;
  const youTubeStart = getPlayer(MediaPlayerId.YouTube)?.previewStart;
  const youTubePosition = getPlayer(MediaPlayerId.YouTube)?.positionId;
  const spotifyUrl = getPlayer(MediaPlayerId.Spotify)?.mediaPlayerUrl;
  const showAnthologyPlayer =
    slinky?.mediaPlayers?.some(
      (player) =>
        player.platformLinkId === MediaPlayerId.Anthology && player.isActive
    ) && slinky.formatLinkId;
  // const destLookup = useMemo<{ [key: number]: DestinationVm }>(
  //   () => Object.fromEntries(destinations.map((x) => [x.destinationId, x])),
  //   [destinations]
  // );

  // Yes I know media-queries exist but we want to be able to force a layout for preview purposes
  const isSmallerLayout = useCssBreakpoint("lg");
  const isSmall = forceMobile === undefined ? isSmallerLayout : forceMobile;
  const [showVideo, setShowVideo] = useState(false);

  // Reset video player when video changed to just show play button
  useEffect(() => {
    setShowVideo(false);
  }, [youTubeUrl]);

  const { data: socialmediaPlatforms } =
    useGetApiSlinkyResourceSocialMediaPlatformsQuery(undefined, {
      skip: !isPreview,
    });

  const buildSocialLink = (socialMediaPlatformId: number) => {
    // If preview and data not saved, check for socialmediaPlatforms
    const socialMediaPlatformMatch = socialmediaPlatforms?.find(
      (socialmediaPlatforms) =>
        socialmediaPlatforms.socialMediaPlatformId === socialMediaPlatformId
    );

    const dataSocial = slinky?.socials?.find(
      (social) => social.socialMediaPlatformLinkId === socialMediaPlatformId
    );

    const social = {
      ...socialMediaPlatformMatch,
      ...dataSocial,
    };

    // (socialMediaPlatformMatch && {
    //   // socialMediaLinkId: 4774,
    //   socialMediaPlatformLinkId: socialMediaPlatformMatch.socialMediaPlatformId,
    //   socialMediaIdentifier: "thespiritualsmusic",
    //   sequence: 1,
    //   platformName: "Facebook",
    //   platformLogoUrl: "Facebook_placeholder.png",
    //   pLatformPromptText: "Facebook Handle",
    //   platformPrefix: "https://www.facebook.com/",
    // });
    let socialIcon = socialIcons.find(
      (s) => s.socialMediaPlatformId === socialMediaPlatformId
    );
    let socialUrl: string = social?.platformPrefix
      ? social?.platformPrefix + "" + social?.socialMediaIdentifier
      : "";
    if (!social?.platformPrefix && social?.platformName === "Website") {
      socialUrl = social?.socialMediaIdentifier || "";
    }
    return socialUrl ? (
      <a
        href={socialUrl}
        rel="noreferrer"
        target="_blank"
        onClick={() => handlePixelEvent(PixelEventType.SocialsClick)}
      >
        {socialIcon?.icon && (
          <CircleIconButton
            className={style.socialIcon}
            tooltipTitle={social?.platformName || "Open link"}
          >
            {socialIcon.icon}
          </CircleIconButton>
        )}
      </a>
    ) : (
      <></>
    );
  };

  const formattedYoutubeUrl = useMemo(() => {
    return (youTubeUrl && formatYoutubeUrl(youTubeUrl)) || "";
  }, [youTubeUrl]);

  const formattedSpotifyUrl = useMemo(() => {
    return (spotifyUrl && formatSpotifyUrl(spotifyUrl)) || "";
  }, [spotifyUrl]);

  // Territory / Language

  const { territoryId: storedTerritoryId, languageId: storedLanguageId } =
    getSlinkyFanPreferences();
  const territoryId =
    previewTerritoryId ||
    storedTerritoryId ||
    slinky?.requestOriginTerritoryId ||
    80;
  const browserMatchedLanguage = languages?.find(
    (language) => navigator.language.split("-")[0] === language.isoCode
  )?.id;
  const languageId = storedLanguageId || browserMatchedLanguage || 10;
  const territory = territories?.find(
    (territory) => territory.id === territoryId
  );
  const [showTerritoryDialog, setShowTerritoryDialog] = useState(false);
  const [presaveProps, setPresaveProps] = useState<SlinkyPresaveProps>();
  const onSaveTerritoryLanguage = (data: TerritoryLanguage) => {
    setShowTerritoryDialog(false);
    localStorage.setItem(
      SlinkyLocalStorageNames.preferences,
      JSON.stringify(data)
    );
  };

  const linksToShow = getLinksToShow(slinky, territoryId);

  const mainTitle = slinky?.titles?.[0]?.titleText;
  const subtitle = slinky?.titles?.[0]?.subTitleText ?? "";

  // Small header for countdown

  const { smallHeader } = useSlinkyCountdown({
    slinky,
    territoryId,
    languageId,
    viewDate,
    isSmall,
  });

  // Scrolling

  const prefersReducedMotion = useMediaQuery(
    "(prefers-reduced-motion: reduce)"
  );

  const contentRef = useRef<HTMLElement>(null);
  const contentHeight = contentRef?.current?.offsetHeight;
  const contentInnerMinHeight = isSmall
    ? (contentHeight || 0) + 150
    : undefined;

  useEffect(() => {
    if (!isSmall) {
      return;
    }
    const handleScroll = (e: any) => {
      if (prefersReducedMotion) return;
      const position = e.target.scrollTop;
      setScrollPosition(position);
    };
    contentRef.current?.addEventListener("scroll", handleScroll);
    return () =>
      contentRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  const noSocials = slinky.socials?.length === 0;
  const imageHeight = smallHeader
    ? 160
    : isSmall
      ? noSocials
        ? 190
        : 250
      : 400;
  const scrollShowHeader = scrollPosition > imageHeight * 0.2;
  const scrollHideImage = scrollPosition > imageHeight * 0.4;

  const defaultImageHeight = smallHeader ? 170 : 190;

  const socialsList = (
    <Stack direction={"row"} className={style.socials}>
      {slinky?.socials?.map((s) => (
        <Fragment key={s.sequence}>
          {buildSocialLink(s.socialMediaPlatformLinkId!)}
        </Fragment>
      ))}
    </Stack>
  );

  const hideSx = {
    opacity: 0,
    pointerEvents: "none",
  };

  const mainImageUnscrolledHeight =
    imageHeight + (scrollShowHeader || !isSmall ? 70 : defaultImageHeight);

  const mainImageHeight = isSmall
    ? `calc(${mainImageUnscrolledHeight}px - ${scrollPosition}px)`
    : "auto";

  const { getSlinkyCta } = useSlinkyTerritoryCta(viewDate, languageId);

  // Link colour

  const { primaryColour, backgroundColourList } = useImageColours(
    imageUrl || "",
    {
      isDark: true,
      palette: {
        primary: {
          main: "#ffffff",
        },
        background: {
          default: "#000000",
        },
      } as any,
    }
  );

  // Audio player

  const [showTracks, setShowTracks] = useState(false);

  const configArea = (
    <Stack direction={"row"} gap={2}>
      <Link
        color={primaryColour}
        sx={{ textDecoration: "none", cursor: "pointer" }}
        onClick={() => setShowManagePermissions(true)}
        typography={"body2"}
      >
        Manage permissions
      </Link>
      <Typography>|</Typography>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => setShowTerritoryDialog(true)}
      >
        {territory?.isoCode && (
          <Tooltip title="Change your location/language">
            <Stack direction={"row"} gap={1}>
              <Flag countryCode={territory?.isoCode} height={20} />
              <Stack direction={"row"} gap={1}>
                <Typography
                  textTransform={"uppercase"}
                  typography={"body2"}
                  color="#ffffff"
                >
                  {territory?.isoCode.toUpperCase()}
                </Typography>
                <Typography
                  textTransform={"uppercase"}
                  color={"#cccccc"}
                  typography={"body2"}
                >
                  (
                  {
                    languages?.find((language) => language.id === languageId)
                      ?.isoCode
                  }
                  )
                </Typography>
              </Stack>
            </Stack>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );

  // Presave details

  const applePresave = (
    destinationFormatId: string | null,
    destinationLinkId: number,
    formatId: number
  ) => {
    const destinationName = getDestinationName(destinationLinkId);
    handlePixelEvent(
      PixelEventType.PreSaveStart,
      destinationName,
      destinationLinkId
    );
    if (isPreview) {
      return;
    }
    if (slinky?.appleMusicToken) {
      const musicKitInstance = window.MusicKit?.configure({
        developerToken: slinky?.appleMusicToken,
        app: {
          name: "music.com.absolutepresave",
          build: "1.1.1",
        },
      });
      musicKitInstance
        .authorize()
        .then((token: string) => {
          // musicKitInstance.api
          //   // .addToLibrary({ albums: [destinationFormatId] })
          //   .then(() => {
          const format = slinky?.relatedFormats?.find(
            (format) => format.formatId === formatId
          );
          if (format) {
            setPresaveProps({
              platformId: Platforms.Apple_Music,
              destinationFormatId: destinationFormatId,
              destinationLinkId: destinationLinkId,
              destinationName: destinationName,
              format: format,
              userAppleMusicToken: token,
            });
          }
        })
        .catch((error: any) => {
          console.error("Error presaving album:", error);
        });
      // });
    } else {
      alert("Presave is not currently available");
    }
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const invalid = params.get("invalid");
  const noscope = params.get("noscope") || params.get("err") === "noscope";
  const ps = params.get("ps");
  const c = params.get("c");
  const noplay = params.get("noplay") !== null;

  useEffect(() => {
    if (!!presaveProps) {
      return;
    }
    if (invalid) {
      alert(
        "Opps, something when wrong with your authentication. Please try again later."
      );
    } else if (noscope) {
      alert(
        "Opps, something when wrong with your authentication. You may have not given the required permissions or you might need to try a different browser or clear your cookies."
      );
    } else if (ps && c) {
      const link = linksToShow.find(
        (link) => link.slinkyDestinationLinkId === parseInt(c)
      );
      if (!link?.destinationLinkId || !link?.destinationName) {
        return alert("Error loading presave");
      }
      const cta = getSlinkyCta(link, territoryId);
      const format = getLinkFormat(link);
      setPresaveProps({
        ps: ps,
        // platformId: cta.presavePlatform,
        platformId: link.platformId,
        destinationFormatId: cta.destinationFormatId || "",
        destinationLinkId: link?.destinationLinkId,
        destinationName: link?.destinationName,
        format: format,
        noPlaylists: !!noplay,
      });
    }
  }, [ps, c, invalid, noscope, noplay]);

  const onClickCta = async (link: LinkVm, cta: SlinkyCta) => {
    if (cta.presavePlatform) {
      if (cta.presavePlatform === Platforms.Apple_Music) {
        if (link.destinationLinkId && link?.formatId) {
          applePresave(
            cta.destinationFormatId || null,
            link.destinationLinkId,
            link.formatId
          );
        } else {
          console.log("Presave is not currently available");
        }
      }
    }

    handleInteraction?.onClick(link, cta, false);
  };

  const getDestinationName = (destinationLinkId: number) =>
    (slinky.links &&
      slinky.links.find((link) => link.destinationLinkId === destinationLinkId)
        ?.destinationName) ||
    "";

  // iFrame players

  const onClickIframePlayer = (mediaPlayerId: number) => {
    handleInteraction?.onPlay?.(mediaPlayerId);
  };

  // Manage permissions

  const [showManagePermissions, setShowManagePermissions] = useState(false);

  const savePermissions = (hasConsented: boolean) => {
    const newConsent: Consent = {
      hasConsented: hasConsented,
      consentTimestamp: new Date().toISOString(),
    };
    localStorage.setItem(
      SlinkyLocalStorageNames.permissions,
      JSON.stringify(newConsent)
    );
    setShowManagePermissions(false);
    setConsent(newConsent);
  };

  const handleSavePermissionsDialog = (value: boolean) => {
    savePermissions(value);
  };

  const [consent, setConsent] = useState<Consent>();

  useEffect(() => {
    const consentJSON = localStorage.getItem(
      SlinkyLocalStorageNames.permissions
    );
    const consent: Consent = consentJSON && JSON.parse(consentJSON);
    setConsent(consent);
  }, []);

  useEffect(() => {
    setShowManagePermissions(!consent && !!slinky.pixels && !isPreview);
  }, [consent, slinky.pixels, isPreview]);

  // Video

  const playVideo = () => {
    setShowVideo(true);
    onClickIframePlayer(MediaPlayerId.YouTube);
    handlePixelEvent(PixelEventType.VideoPreview);
  };

  // Tracking

  useEffect(() => {
    if (consent?.hasConsented && slinky.pixels) {
      handlePixels(slinky.pixels);
    }
  }, [consent?.hasConsented, slinky.pixels]);

  const artistNames = slinky?.artists
    ?.map((artist) => artist.displayName)
    .join(" / ");

  const desktopContentInnerVerticalPaddingPx = 65;

  const bgStyleApplied =
    slinky.lookAndFeel && getSlinkyBgSxProps(slinky.lookAndFeel);

  const getLinkFormat = (link: LinkVm) => {
    return slinky?.relatedFormats?.find(
      (format) => format.formatId === link.formatId
    );
  };

  const getFormatTypeText = (link: LinkVm) => {
    const format = getLinkFormat(link);
    if (!format) return "";
    const { formatShortName, isDigital } = format;
    if (isDigital) {
      return "";
    }
    const hasOtherFormatTypes =
      slinky?.relatedFormats &&
      slinky?.relatedFormats.some(
        (otherFormat) => otherFormat.formatShortName !== formatShortName
      );
    if (hasOtherFormatTypes) {
      return formatShortName;
    }
  };

  // const mainReleaseDate = getSlinkyReleaseDate(slinky, territoryId);

  const getSubtitleText = (link: LinkVm) => {
    let subTitle = "";
    const format = slinky?.relatedFormats?.find(
      (format) => format.formatId === link.formatId
    );
    if (format) {
      const otherFormatLinks =
        slinky?.links &&
        slinky.links.filter(
          (otherLink) =>
            otherLink.destinationLinkId === link.destinationLinkId &&
            otherLink.formatId !== link.formatId
        );
      const otherFormatIds = otherFormatLinks?.map((link) => link.formatId);
      const otherFormats = slinky?.relatedFormats?.filter((format) =>
        otherFormatIds?.includes(format.formatId)
      );
      const hasOtherFormatSubtitles = otherFormats?.some(
        (otherFormat) => otherFormat.subTitle !== format.subTitle
      );
      if (hasOtherFormatSubtitles) {
        subTitle = format.subTitle || "";
      }
    }
    // if (!subTitle) {
    //   const linkReleaseDate = getLinkTerritoryDate(link, territoryId);
    //   if (!linkReleaseDate || !mainReleaseDate) return "";
    //   const linkReleaseDay = formatDate(new Date(linkReleaseDate), "d MMM");
    //   const mainReleaseDay = formatDate(new Date(mainReleaseDate), "d MMM");
    //   if (linkReleaseDate && linkReleaseDay !== mainReleaseDay) {
    //     subTitle = formatDate(new Date(linkReleaseDate), "d MMM");
    //   }
    // }
    return subTitle;
  };

  // Presave end

  const [showPresaveSuccess, setShowPresaveSuccess] = useState(false);
  const [showPresaveError, setShowPresaveError] = useState(false);
  const handleClosePresave = (isSuccess?: boolean, isError?: boolean) => {
    if (isSuccess) {
      setShowPresaveSuccess(true);
    }
    if (isError) {
      setShowPresaveError(true);
    }
    setPresaveProps(undefined);
  };

  // Check private browsing

  const [isPrivateMode, setIsPrivateMode] = useState(false);

  const applyPrivateMode = async () => {
    const privateModeCheck = await checkPrivateMode();
    setIsPrivateMode(privateModeCheck);
  };

  useEffect(() => {
    applyPrivateMode();
  }, []);

  const youtubePositionIsDefault =
    youTubePosition === MediaPlayerPosition.Standard ||
    !youTubePosition ||
    !formattedYoutubeUrl;
  const youtubeIframe = formattedYoutubeUrl && (
    <iframe
      src={`${formattedYoutubeUrl}?autoplay=${youtubePositionIsDefault ? 1 : 0}${
        youTubeStart ? "&start=" + youTubeStart : ""
      }&rel=0`}
      title="YouTube video player"
      allow="autoplay"
      className={`${style.videoPlayerIframe} ${youtubePositionIsDefault ? "" : style.videoPlayerIframePositioned}`}
      onClick={() => onClickIframePlayer(MediaPlayerId.YouTube)}
    ></iframe>
  );

  // const wrappedYoutubeIframe = (
  //   <Box my={2} maxHeight={"20vh"}>
  //     {youtubeIframe}
  //   </Box>
  // );
  const abovePackShotYoutubeIframe =
    youTubePosition === MediaPlayerPosition.Above_Cover_Art && youtubeIframe;
  const belowPackShotYoutubeIframe =
    youTubePosition === MediaPlayerPosition.Below_Cover_Art && youtubeIframe;

  const smallPackshotSize = isSmall ? 60 : 100;

  return (
    <>
      <Box
        className={`${style.container + (isSmall ? "" : " " + style.desktop)} slinkyContainer`}
        sx={{
          backgroundImage: !isSmall ? `url(${imageUrl})` : "",
          contain: "inline-size",
        }}
      >
        <Box
          className={style.containerInner}
          sx={isSmall ? undefined : bgStyleApplied}
        >
          {isSmall && (
            <Stack
              className={style.topBarTitle}
              sx={scrollShowHeader ? {} : hideSx}
            >
              <Typography color={"#ffffff"}>{mainTitle}</Typography>
            </Stack>
          )}
          <Box
            className={style.content}
            ref={contentRef}
            height={isSmall ? "100vh" : "auto"}
          >
            <Stack
              className="mainContentArea"
              width={"100%"}
              direction={isSmall ? "column" : "row"}
              columnGap={5}
              alignItems={"flex-start"}
              minHeight={contentInnerMinHeight}
              py={isSmall ? 0 : `${desktopContentInnerVerticalPaddingPx}px`}
              flex={1}
            >
              <Stack
                width="100%"
                height={
                  isSmall
                    ? "auto"
                    : `calc(100vh - ${desktopContentInnerVerticalPaddingPx * 3}px)`
                }
                justifyContent={"center"}
              >
                {/* {!isSmall && (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    p={1.5}
                  >
                    <Box />
                    <Stack alignItems={"center"} gap={1}>
                      <Typography
                        variant="h2"
                        fontWeight={600}
                        textAlign={"center"}
                        color={"text.primary"}
                      >
                        {mainTitle}
                      </Typography>
                      <Typography variant="h4" color={"text.secondary"}>
                        {subtitle}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}></Stack>
                  </Stack>
                )} */}
                <Box
                  className={`${style.main_image} ${
                    showTracks ? style.main_imageShowTracks : ""
                  }`}
                  height={showTracks ? "auto" : mainImageHeight}
                  position={isSmall ? "absolute" : "relative"}
                >
                  {!showVideo && !showTracks && (
                    <Stack
                      className={style.artwork}
                      // position={isSmall ? 'absolute' : 'relative'}
                      // top={isSmall ? `calc(10px + ${scrollPosition + (scrollShowHeader ? 28 : 0)}px)` : 0}
                      sx={
                        scrollHideImage
                          ? hideSx
                          : scrollShowHeader
                            ? { pt: 14 }
                            : { py: 3 }
                      }
                    >
                      {abovePackShotYoutubeIframe}
                      {!!imageUrl && !abovePackShotYoutubeIframe && (
                        <img
                          src={imageUrl}
                          alt=""
                          style={{
                            width: isSmall
                              ? youtubePositionIsDefault
                                ? `calc(${imageHeight}px - ${
                                    scrollPosition * 2
                                  }px)`
                                : 100
                              : "40vh",
                          }}
                        />
                      )}

                      {!!youTubeUrl && youtubePositionIsDefault && (
                        <Box
                          className={style.videoPlayerButtonContainer}
                          pt={`${scrollPosition + (scrollShowHeader ? 70 : 0)}px`}
                        >
                          <CircleIconButton
                            className={style.videoPlayButton}
                            sx={{
                              background: "rgba(50,50,50,0.9)",
                              backdropFilter: "blur(5px)",
                              p: 1.5,
                            }}
                            onClick={playVideo}
                          >
                            <BsYoutube size={60} />
                          </CircleIconButton>
                        </Box>
                      )}
                    </Stack>
                  )}
                  {!!youTubeUrl && !showTracks && youtubePositionIsDefault ? (
                    <>
                      {showVideo && (
                        <Box
                          className={style.videoPlayerContainer}
                          sx={scrollHideImage ? hideSx : {}}
                        >
                          {youtubeIframe}
                        </Box>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <Stack
                    direction={"row"}
                    gap={1}
                    justifyContent={showTracks ? "flex-start" : "center"}
                    alignItems={"center"}
                    zIndex={1}
                  >
                    {showTracks && imageUrl && isSmall && (
                      <ImageWrapper
                        width={60}
                        src={imageUrl}
                        style={{ marginLeft: 15 }}
                      />
                    )}
                    <Box
                      className={style.titlearea}
                      sx={{
                        opacity: 1 - scrollPosition * 0.005,
                        transform: `scale(calc(1 - ${scrollPosition})) translate(-${scrollPosition}px 0px)`,
                      }}
                      maxHeight={
                        isSmall ? (scrollShowHeader ? 0 : 200) : undefined
                      }
                    >
                      {abovePackShotYoutubeIframe && imageUrl && (
                        <ImageWrapper
                          src={imageUrl}
                          width={smallPackshotSize}
                          height={smallPackshotSize}
                          style={{ marginBottom: 15 }}
                        />
                      )}
                      {mainTitle && (
                        <Typography
                          color={"text.primary"}
                          className={style.title}
                          fontWeight={isSmall ? "normal" : "bold"}
                          lineHeight={isSmall ? undefined : "50px"}
                          mb={smallHeader ? "5px" : "15px"}
                          variant={
                            mainTitle?.length > 30
                              ? isSmall
                                ? "h5"
                                : "h4"
                              : mainTitle?.length > 20 || smallHeader
                                ? isSmall
                                  ? "h3"
                                  : "h2"
                                : isSmall
                                  ? "h2"
                                  : "h1"
                          }
                        >
                          {mainTitle}
                        </Typography>
                      )}
                      <Typography
                        color={"text.primary"}
                        className={style.subtitle}
                        variant={
                          subtitle?.length > 30
                            ? "h5"
                            : subtitle?.length > 20 || smallHeader
                              ? "h4"
                              : "h4"
                        }
                      >
                        {subtitle}
                      </Typography>
                      {!showTracks && socialsList}
                    </Box>
                  </Stack>
                </Box>
                {isSmall && (
                  <Box
                    style={{ backgroundImage: `url(${imageUrl})` }}
                    className={style.bgImage}
                  >
                    <Box
                      sx={bgStyleApplied}
                      height="100%"
                      width="100%"
                      pt={`${mainImageUnscrolledHeight}px`}
                    />
                  </Box>
                )}
                {belowPackShotYoutubeIframe}
                {showAnthologyPlayer && (
                  <MiniAudioPlayer
                    formatId={slinky?.formatLinkId!}
                    showTracks={showTracks}
                    onToggleTracks={() => setShowTracks(!showTracks)}
                  />
                )}
                {!!spotifyUrl && (
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <SlinkySpotifyPlayer
                      url={formattedSpotifyUrl}
                      onClick={() => onClickIframePlayer(MediaPlayerId.Spotify)}
                    />
                  </Stack>
                )}
              </Stack>
              <Stack className={style.linkarea} p={isSmall ? 0 : 3}>
                {!prefersReducedMotion && (
                  <SlinkyCountDown
                    slinky={slinky}
                    territoryId={territoryId}
                    languageId={languageId}
                    viewDate={viewDate}
                    isSmall={isSmall}
                    colors={[
                      ...(primaryColour ? [primaryColour] : []),
                      ...(backgroundColourList || []),
                    ]}
                  />
                )}
                {isPrivateMode && (
                  <Alert>
                    You seem to be in private mode. Pre-Saving and Pre-Ordering
                    may not work.
                  </Alert>
                )}
                {isLoading
                  ? _.range(5).map((index: number) => (
                      <Skeleton key={index} width={"100%"} height={85} />
                    ))
                  : linksToShow.map((l, idx) => {
                      const cta = getSlinkyCta(l, territoryId);
                      const formatTypeText = getFormatTypeText(l);
                      const subTitleText = getSubtitleText(l);
                      const invalidLink = !cta.presavePlatform && !cta.url;
                      const isApplePresave =
                        cta.presavePlatform === Platforms.Apple_Music;
                      const guid = Guid.create().toString();
                      const preSaveState: PreSaveStateVm = {
                        guid: guid,
                        slinkyUrl: `${location.protocol}//${location.host}${location.pathname}`,
                        destinationId: l.destinationLinkId as Destinations,
                        ipterritoryIso3code: territory?.isoCode!,
                        utcminuteOffSet: new Date().getTimezoneOffset(),
                        slinkyDestinationLinkId: l.slinkyDestinationLinkId!,
                      };
                      const presaveStateEncoded = btoa(
                        unescape(
                          encodeURIComponent(JSON.stringify(preSaveState))
                        )
                      );
                      const redirectUrl = getRedirectUrl(
                        presaveStateEncoded,
                        cta.presavePlatform,
                        os
                      );
                      const clickEvent: ClickEventVm = {
                        landingUid: landingUid || "",
                        correlationId: correlationId || "",
                        slinkyLinkId: slinky?.slinkyId || 0,
                        destinationId: l.destinationLinkId!,
                        slinkyDestinationTerritoryId: territoryId,
                        ctaId: cta.ctaId || 0,
                        isClickThrough: false,
                        redirectUrl: redirectUrl,
                        stateGuid: guid,
                      };
                      const clickEventEncoded = btoa(
                        unescape(encodeURIComponent(JSON.stringify(clickEvent)))
                      );
                      const presaveHref =
                        !!cta.presavePlatform &&
                        `${defaultBaseUrl}/api/SlinkyPreSave/StartPreSave?vm=${clickEventEncoded}${isMobile ? "&sr=true" : ""}`;
                      const buttonHref = isApplePresave
                        ? ""
                        : presaveHref || cta.url || "";
                      const format = getLinkFormat(l);
                      const isImmersive =
                        format?.formatShortName === "Immersive";
                      const immersiveText = isImmersive ? "Dolby Atmos" : "";
                      const bomttomText = subTitleText || immersiveText;
                      return (
                        <Fragment key={idx}>
                          {invalidLink ? (
                            <></>
                          ) : (
                            <Box className={style.linkbox}>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                gap={1.5}
                              >
                                <Stack
                                  direction={"row"}
                                  gap={1}
                                  alignItems={"center"}
                                >
                                  <Box className={style.logo}>
                                    <ImageWrapper
                                      src={l.destinationImageUrl ?? ""}
                                      alt=""
                                      fallbackSrc={
                                        imageUrl || slinkyDestinationFallBackImg
                                      }
                                    />
                                  </Box>
                                  <Stack
                                    className={`${style.link_destination} ${style.oneLineTruncate}`}
                                  >
                                    <Typography
                                      variant="sub4"
                                      color={"#ffffff"}
                                      className={style.oneLineTruncate}
                                    >
                                      {l.customDestinationName ||
                                        l.destinationName}
                                    </Typography>
                                    {bomttomText && (
                                      <Typography
                                        variant="labelMedium"
                                        fontWeight={"light"}
                                        color={"#ffffff"}
                                        className={style.oneLineTruncate}
                                      >
                                        {bomttomText}
                                      </Typography>
                                    )}
                                  </Stack>
                                  {isImmersive && !!subTitleText && (
                                    <Box className={style.immersiveIcon}>
                                      <DolbyImmersiveIcon />
                                    </Box>
                                  )}
                                </Stack>
                                <ButtonWrapper
                                  className={`${style.cta} ${style.oneLineTruncate}`}
                                  sx={{
                                    borderColor: primaryColour,
                                    ":hover": { borderColor: primaryColour },
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onClick={(e) => {
                                    if (
                                      window.location.href.indexOf(
                                        "/slinkypublic/"
                                      ) > -1
                                    ) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }

                                    if (
                                      isMobile &&
                                      cta.presavePlatform !==
                                        Platforms.Apple_Music
                                    ) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      fetch(buttonHref).then(() => {
                                        if (os !== MobileDeviceOs.Unknown) {
                                          location.href = redirectUrl;
                                        }
                                        setTimeout(() => {
                                          location.href = redirectUrl;
                                        }, 200);
                                      });
                                    }
                                    onClickCta(l, cta);
                                  }}
                                  href={buttonHref}
                                >
                                  <Stack
                                    direction={"row"}
                                    gap={0.5}
                                    alignItems={"center"}
                                    className={style.oneLineTruncate}
                                  >
                                    <Typography
                                      variant={isSmall ? "labelLarge" : "sub4"}
                                      color={"#fafafa"}
                                      fontWeight={"normal"}
                                      lineHeight={isSmall ? undefined : 1}
                                      className={style.oneLineTruncate}
                                    >
                                      {cta.text}
                                      {formatTypeText
                                        ? ` ${formatTypeText}`
                                        : ""}
                                    </Typography>
                                  </Stack>
                                </ButtonWrapper>
                              </Stack>
                            </Box>
                          )}
                        </Fragment>
                      );
                    })}
              </Stack>
            </Stack>
            <Stack
              width={"100%"}
              alignItems={isSmall ? "center" : "flex-end"}
              p={isSmall ? 3 : 1.5}
            >
              {configArea}
            </Stack>
          </Box>
        </Box>
      </Box>

      <SlinkyTerritoryLanguageDialog
        open={showTerritoryDialog}
        onClose={() => setShowTerritoryDialog(false)}
        territoryId={territoryId}
        languageId={languageId}
        onSave={onSaveTerritoryLanguage}
        territories={territories}
        languages={languages}
      />
      <SlinkyPreSaveDialog
        slinkyId={slinky?.slinkyId || 0}
        open={!!presaveProps}
        onClose={handleClosePresave}
        presaveProps={presaveProps}
        artists={slinky.artists || []}
        slinkyImage={imageUrl || ""}
        slinkyTitle={mainTitle || ""}
      />
      <SlinkyManagePermissions
        open={showManagePermissions}
        handleSavePermissions={handleSavePermissionsDialog}
        artistNames={artistNames}
      />
      <Snackbar
        open={showPresaveSuccess}
        autoHideDuration={6000}
        onClose={() => setShowPresaveSuccess(false)}
      >
        <Alert
          onClose={() => setShowPresaveSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Done! Release will be added to your library once live.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showPresaveError}
        autoHideDuration={6000}
        onClose={() => setShowPresaveError(false)}
      >
        <Alert
          onClose={() => setShowPresaveError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Uh oh, there was an error presaving
        </Alert>
      </Snackbar>
    </>
  );
}

const socialIcons: any[] = [
  {
    socialMediaPlatformId: 1,
    icon: <FaFacebook />,
  },
  {
    socialMediaPlatformId: 2,
    icon: <FaInstagram />,
  },
  {
    socialMediaPlatformId: 3,
    icon: <FaXTwitter />,
  },
  {
    socialMediaPlatformId: 4,
    icon: <FaSnapchat />,
  },
  {
    socialMediaPlatformId: 5,
    icon: <FaTiktok />,
  },
  {
    socialMediaPlatformId: 6,
    icon: <FaGlobe />,
  },
  {
    socialMediaPlatformId: 7,
    icon: <FaSoundcloud />,
  },
  {
    socialMediaPlatformId: 8,
    icon: <FaYoutube />,
  },
];
