'use client';

import {
  ClickEventVm,
  LinkVm,
  PlayEventVm,
  SelectListVm,
  slinkyApi,
  SlinkyVm,
} from '@anthology/shared/src/api/slinkyApi';
import {
  getLinksToShow,
  getSlinkyFanPreferences,
  handlePixelEvent,
  PixelEventType,
  RenderSlinky,
  SlinkyCta,
  SlinkyLocalStorageNames,
  useSlinkyTerritoryCta,
} from '@anthology/shared/src/components/slinky';
import { slinkyIconUrl } from "@anthology/shared/src/constants/urls";
import { Box, Stack, Typography } from "@mui/material";
import { Guid } from "guid-typescript";
import Image from "next/image";
import Script from "next/script";
import { useEffect, useState } from "react";
import { Platforms } from "../../../../ws_shared/src/api/types/autoEnum";
import DatadogInit from "../../datadog-init";
import { store } from "../../features/store";

const generateCorrelationId = () => {
  try {
    return crypto.randomUUID().toString();
  } catch {
    return Guid.create().toString();
  }
};

type Props = {
  data: {
    slinky: SlinkyVm;
    territories: SelectListVm[];
    languages: SelectListVm[];
  };
  searchParams: { [key: string]: string | undefined };
  refererHost: string;
  isError?: boolean;
  errorMessage?: string;
  headersString: string;
};

const getIpAddressCountry = async () => {
  const ipCountry = await fetch(`https://api.hostip.info/country.php`);
  return await ipCountry.text();
};

export function SlinkyClient({
                               data,
                               searchParams,
                               refererHost,
                               isError,
                               errorMessage,
                               headersString,
                             }: Props) {
  const { slinky, territories, languages } = data;
  const { getSlinkyCta } = useSlinkyTerritoryCta();

  const storedTerritoryId = getSlinkyFanPreferences()?.territoryId;
  const [ipAddressTerritoryId, setIpAddressTerrityId] = useState<number>();
  useEffect(() => {
    if (
      !slinky ||
      !territories ||
      ipAddressTerritoryId ||
      slinky?.requestOriginTerritoryId ||
      storedTerritoryId
    )
      return;
    getIpAddressCountry().then((country) => {
      const territoryId = territories.find((t) => t.isoCode === country)?.id;
      if (territoryId) {
        setIpAddressTerrityId(territoryId);
      }
    });
  }, [
    ipAddressTerritoryId,
    slinky,
    slinky?.requestOriginTerritoryId,
    storedTerritoryId,
    territories,
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hostname === 'slinky-next-test.web.app') {
      console.log('header list', headersString);
    }
  }, [headersString]);
  
  const territoryId =
    storedTerritoryId ||
    slinky?.requestOriginTerritoryId ||
    ipAddressTerritoryId;

  const [correlationId, setCorrelationId] = useState('');
  const [uid, setUid] = useState('');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const storedCorrelationId = localStorage.getItem(
      SlinkyLocalStorageNames.correlationId,
    );
    if (storedCorrelationId) {
      setCorrelationId(storedCorrelationId);
    } else {
      const newCorrelationId = generateCorrelationId();
      setCorrelationId(newCorrelationId);
      localStorage.setItem(
        SlinkyLocalStorageNames.correlationId,
        newCorrelationId,
      );
    }
    setUid(generateCorrelationId());
  }, []);

  useEffect(() => {
    if (slinky?.slinkyUrlextension) {
      localStorage.setItem(
        SlinkyLocalStorageNames.urlExtension,
        slinky.slinkyUrlextension,
      );
    }
    if (slinky?.lookAndFeel?.coverArtImageUrl) {
      localStorage.setItem(
        SlinkyLocalStorageNames.imageUrl,
        slinky.lookAndFeel.coverArtImageUrl,
      );
    }
  }, [slinky]);

  useEffect(() => {
    const linksToShow = getLinksToShow(slinky, territoryId || 0);
    if (linksToShow.length === 1 && slinky?.skipOnSingleVisibleLink) {
      const cta = getSlinkyCta(linksToShow[0], territoryId);
      if (cta.url && !cta.presavePlatform) {
        window.location.href = cta.url;
      }
    }
  }, [getSlinkyCta, slinky, territoryId]);

  const onClickLink = (
    link: LinkVm,
    cta: SlinkyCta,
    isClickThrough: boolean,
  ) => {
    const { presavePlatform } = cta;
    if (presavePlatform && presavePlatform !== Platforms.Apple_Music) {
      // Click for presaves is handled by back end, except for Apple Music
      return;
    }
    const interaction: ClickEventVm = {
      landingUid: uid,
      slinkyLinkId: slinky?.slinkyId || 0,
      destinationId: link.destinationLinkId || 0,
      slinkyDestinationTerritoryId: cta.slinkyDestinationLinkTerritorieId || 0,
      isClickThrough,
      ctaId: cta.ctaId,
      correlationId,
    };

    store
      .dispatch(
        slinkyApi.endpoints.postApiSlinkyEventRecordClickEvent.initiate(
          interaction,
        ),
      )
      .finally(() => {
        if (link.destinationName) {
          try {
            handlePixelEvent(
              PixelEventType.ViewContent,
              link.destinationName,
              link.destinationLinkId,
            );
            handlePixelEvent(
              PixelEventType.Click,
              link.destinationName,
              link.destinationLinkId,
            );
          } catch {
            console.log('Failed pixel');
          }
          if (cta.url && !cta.presavePlatform) {
            window.location.href = cta.url;
          }
        }
      });
  };

  const onPlayMediaPlayer = (
    mediaPlayerId: number,
    formatId?: number,
    assetId?: number,
    formatAssetId?: number,
  ) => {
    const interaction: PlayEventVm = {
      landingUid: uid,
      correlationId,
      slinkyLinkId: slinky?.slinkyId || 0,
      mediaPlayerId,
      formatId: formatId || 0,
      assetId: assetId || 0,
      formatAssetId: formatAssetId || 0,
    };
    store.dispatch(
      slinkyApi.endpoints.postApiSlinkyEventRecordPlayEvent.initiate(
        interaction,
      ),
    );
  };

  useEffect(() => {
    if (searchParams && correlationId && uid && slinky?.slinkyId) {
      const spotifyCode = searchParams.spotifyCode;
      if (slinky && !spotifyCode) {
        store.dispatch(
          slinkyApi.endpoints.postApiSlinkyEventRecordLandingEvent.initiate({
            uid,
            slinkyId: slinky.slinkyId || 0,
            correlationId,
            trackingTagsJson: searchParams && JSON.stringify(searchParams),
            referrer: refererHost,
          }),
        );
        handlePixelEvent(PixelEventType.PageView);
      }
    }
    setIsClient(true);
  }, [slinky, searchParams, correlationId, uid, refererHost]);

  // Handle redirect
  const redirectUrl = searchParams.redirectUrl;
  if (redirectUrl) {
    let redirectLink = null;
    slinky.links?.forEach((link) => {
      link.territoryGroups?.find((tGroup) => {
        if (
          tGroup.postLinkIdentifierOrUrl === redirectUrl ||
          tGroup.preLinkIdentifierOrUrl === redirectUrl
        ) {
          redirectLink = link;
        }
      });
    });

    if (redirectLink) {
      onClickLink(redirectLink, { url: redirectUrl } as SlinkyCta, true);
    } else if (typeof window !== 'undefined') {
      window.location.href = redirectUrl;
    }
  }

  if (isError) {
    return (
      <Stack className="centreContent" gap={3}>
        <Image
          src={slinkyIconUrl}
          alt="Slinky"
          width={80}
          height={80}
          style={{ filter: 'invert(100%)' }}
        />
        <Typography variant="bodyLarge">Error loading Slinky</Typography>
        {errorMessage && (
          <Typography variant="labelMedium">{errorMessage}</Typography>
        )}
      </Stack>
    );
  }

  return (
    <>
      <DatadogInit />
      {isClient ? (
        <RenderSlinky
          slinky={slinky || {}}
          handleInteraction={{
            onClick: onClickLink,
            onPlay: onPlayMediaPlayer,
          }}
          languages={languages || []}
          territories={territories || []}
          landingUid={uid}
          correlationId={correlationId}
        />
      ) : (
        <Box />
      )}
      <Script src="https://js-cdn.music.apple.com/musickit/v1/musickit.js" />
    </>
  );
}
