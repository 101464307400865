const MiniAudioPlayer = ({}: {
  formatId: number;
  onToggleTracks: () => void;
  showTracks: boolean;
}) => {
  return <></>;
};
// const audio = useAudioService(true);
// const {
//   data: format,
//   isLoading: isLoadingFormat,
//   isError: isErrorFormat,
// } = useGetApiCatalogueGetFormatDetailQuery(formatId);
// const {
//   data: tracks,
//   isLoading: isLoadingTracks,
//   isError: isErrorTracks,
// } = useGetApiProductTrackListQuery({ formatId: formatId });
// const isLoading = isLoadingFormat || isLoadingTracks;
// const isError = isErrorFormat || isErrorTracks;
// useEffect(() => {
//   audio.controls.prepareMiniPlayer(format, tracks);
// }, [audio.controls, format, tracks]);
// const currentTrack = tracks && tracks[audio.state.currentTrack];
// const currentTrackTitle =
//   (currentTrack?.assetTitle &&
//     getAssetFullName(
//       currentTrack?.assetTitle,
//       currentTrack?.assetTitleVersion
//     )) ||
//   `Track ${audio.state.currentTrack + 1}`;
// const iconSize = showTracks ? 25 : 20;
// const chevronSize = showTracks ? 20 : 15;
// const imageUrl = format?.packshotGUID && getPackshotUrl(format?.packshotGUID);
// const colour = useImageColours(imageUrl || "", { isDark: true });
// const backgroundRgb = hexToRgb(colour?.backgroundColour || "");
// const bgRgbValue = getRgbValue(backgroundRgb);
// const bgColour = `rgba(${bgRgbValue.replaceAll(" ", ", ")}, ${showTracks ? "0.45" : "0.35"})`;
// const buttonProps = { py: 0, ":hover": { background: "transparent" } };
// const togglePlay = () =>
//   audio.state.playing ? audio.controls.pause() : audio.controls.play();
// const next = () => audio.controls.next(true);
// const playTrack = (index: number) => audio.controls.goto(index, true);
// const trackLeftItemSize = 25;

//   return isError ? (
//     <Typography
//       variant="labelLarge"
//       color="text.secondary"
//       p={1}
//       textAlign={"center"}
//     >
//       Error loading player
//     </Typography>
//   ) : (
//     <Box p={0.5} zIndex={1}>
//       <Box
//         className={style.background}
//         sx={{ backgroundImage: showTracks ? "" : `url(${imageUrl})` }}
//       >
//         <Box
//           className={`${style.container} oneLineTruncate`}
//           bgcolor={bgColour}
//         >
//           {!showTracks && (
//             <Typography
//               component={Box}
//               variant={showTracks ? "labelLarge" : "labelMedium"}
//               className="oneLineTruncate"
//             >
//               {isLoading ? <Skeleton variant="text" /> : currentTrackTitle}
//             </Typography>
//           )}
//           <Stack
//             direction={"row"}
//             gap={2}
//             alignItems={"center"}
//             width={"100%"}
//             justifyContent={"space-between"}
//             mt={showTracks ? 1 : 0}
//           >
//             <Typography
//               variant="labelSmall"
//               color="text.secondary"
//               className="oneLineTruncate"
//             >
//               {isLoading ? (
//                 <Skeleton variant="text" />
//               ) : showTracks ? (
//                 ""
//               ) : (
//                 format?.title
//               )}
//             </Typography>
//             <Stack direction={"row"}>
//               <CircleIconButton sx={buttonProps} onClick={togglePlay}>
//                 {audio.state.playing ? (
//                   <BsPause size={iconSize} />
//                 ) : (
//                   <BsPlayFill size={iconSize} />
//                 )}
//               </CircleIconButton>
//               <CircleIconButton sx={buttonProps} onClick={() => next()}>
//                 {<BsSkipEndFill size={iconSize} />}
//               </CircleIconButton>
//               <CircleIconButton
//                 sx={buttonProps}
//                 onClick={() => onToggleTracks()}
//               >
//                 {showTracks ? (
//                   <BsChevronDown size={chevronSize} />
//                 ) : (
//                   <BsChevronRight size={chevronSize} />
//                 )}
//               </CircleIconButton>
//             </Stack>
//           </Stack>
//           {tracks && (
//             <Collapse in={showTracks}>
//               <List>
//                 {tracks.map((track, index) => (
//                   <ListItem
//                     key={track.assetId}
//                     className={`${style.trackRow} oneLineTruncate clickable`}
//                     sx={{ px: 0.5 }}
//                     onClick={() => playTrack(index)}
//                   >
//                     {audio.state.currentTrack === index &&
//                     audio.state.playing ? (
//                       <Box
//                         className={style.trackRowPlaying}
//                         minWidth={trackLeftItemSize}
//                       >
//                         <BsVolumeUpFill size={15} />
//                       </Box>
//                     ) : (
//                       <>
//                         <Typography
//                           variant="labelLarge"
//                           color="text.secondary"
//                           minWidth={trackLeftItemSize}
//                           className={style.trackRowNumber}
//                         >
//                           {index + 1}
//                         </Typography>
//                         <Box
//                           className={style.trackRowPlay}
//                           minWidth={trackLeftItemSize}
//                         >
//                           <BsPlayFill />
//                         </Box>
//                       </>
//                     )}
//                     <Typography
//                       variant="labelMedium"
//                       className="oneLineTruncate"
//                     >
//                       {getAssetFullName(
//                         track.assetTitle || "",
//                         track.assetTitleVersion
//                       )}
//                     </Typography>
//                   </ListItem>
//                 ))}
//               </List>
//             </Collapse>
//           )}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

export default MiniAudioPlayer;
