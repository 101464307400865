import { Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Confetti from "react-dom-confetti";
import { SlinkyVm } from "../../../api/slinkyApi";
import style from "../RenderSlinky.module.scss";
import { getSlinkyReleaseDate } from "../slinkyHelpers";
import { useSlinkyTerritoryCta } from "../useSlinkyTerritoryCta";

interface SlinkyCountdownProps {
  slinky: SlinkyVm;
  territoryId: number;
  languageId: number;
  viewDate: Date;
  isSmall?: boolean;
  colors?: string[];
}

export const useSlinkyCountdown = ({
  slinky,
  territoryId,
  languageId,
  viewDate = new Date(),
  isSmall,
}: SlinkyCountdownProps) => {
  // TODO: apply setting
  const { showCountdownClock } = slinky || {};
  const { getSlinkyCta } = useSlinkyTerritoryCta(viewDate, languageId);
  const releaseDate = getSlinkyReleaseDate(slinky, territoryId);
  const diff =
    releaseDate && releaseDate
      ? new Date(releaseDate).getTime() - new Date(viewDate).getTime()
      : 0;

  if (!showCountdownClock) {
    return {
      releaseDate: releaseDate,
      diff: diff,
      showCountdown: showCountdownClock,
      smallHeader: false,
    };
  }

  const canPresave = slinky?.links?.some((link) => {
    return getSlinkyCta(link, territoryId).presavePlatform;
  });

  const isLessThan100Days = diff < 100 * 24 * 60 * 60 * 1000;
  const showCountdown = showCountdownClock && diff > 0 && isLessThan100Days;

  return useMemo(
    () => ({
      showCountdown,
      diff,
      canPresave,
      releaseDate,
      smallHeader: diff > 0 && isSmall,
    }),
    [showCountdown, diff, canPresave, releaseDate, isSmall]
  );
};

const formatNumber = (number: number) =>
  `${number.toString().padStart(2, "0")}`;

const SlinkyCountDownDivider = () => (
  <Typography variant="bodyLarge">:</Typography>
);

const SlinkyCountDown = ({
  slinky,
  territoryId,
  languageId,
  viewDate,
  isSmall,
  colors,
}: SlinkyCountdownProps) => {
  const { diff, releaseDate, showCountdown } = useSlinkyCountdown({
    slinky,
    territoryId,
    languageId,
    viewDate,
  });

  const [countdown, setCountdown] = useState<{
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  }>();

  useEffect(() => {
    if (!showCountdown) return;
    const interval = setInterval(() => {
      if (diff > 0) {
        const updatedDiff = diff - 1000;
        const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
        const updatedHours = Math.floor(
          (updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const updatedMinutes = Math.floor(
          (updatedDiff % (1000 * 60 * 60)) / (1000 * 60)
        );
        const updatedSeconds = Math.floor((updatedDiff % (1000 * 60)) / 1000);

        setCountdown({
          days: formatNumber(updatedDays),
          hours: formatNumber(updatedHours),
          minutes: formatNumber(updatedMinutes),
          seconds: formatNumber(updatedSeconds),
        });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [diff, showCountdown]);

  // TODO: Only show if showCountdown is true

  const releaseDateObj = releaseDate && new Date(releaseDate);
  const releaseTime =
    releaseDateObj &&
    (releaseDateObj.getHours() > 0 ||
      releaseDateObj.getMinutes() > 0 ||
      releaseDateObj.getSeconds() > 0);

  const showYear = Math.floor(diff / (1000 * 60 * 60 * 24)) > 200;
  const showTime = releaseTime && Math.floor(diff / (1000 * 60 * 60 * 24)) < 1;
  const releaseMessage =
    diff > 0 &&
    releaseDateObj &&
    `Out ${format(releaseDateObj, `eeee dd MMMM${showYear ? " yyyy" : ""}`)}${showTime ? ` at ${format(releaseDateObj, `HH:mm`)}` : ""}`;

  const showConfetti = !!slinky.lookAndFeel?.showConfettiOnLoad && diff === 0;

  return (
    <Stack className={style.countdownContainer}>
      {showCountdown && diff && diff > 0 && countdown && (
        <Box
          className={style.countdown}
          sx={{
            ">*": {
              gap: isSmall ? 0.6 : 4,
              ">*": {
                "&:first-of-type": {
                  fontSize: isSmall ? "1.8rem" : "4.5rem",
                },
                "&:last-of-type": {
                  fontSize: isSmall ? "12px" : "20px",
                },
              },
            },
          }}
        >
          <Box>
            <span>{countdown.days}</span>
            <span>Days</span>
          </Box>
          <SlinkyCountDownDivider />
          <Box>
            <span>{countdown.hours}</span>
            <span>Hours</span>
          </Box>
          <SlinkyCountDownDivider />
          <Box>
            <span>{countdown.minutes}</span>
            <span>Minutes</span>
          </Box>
          <SlinkyCountDownDivider />
          <Box>
            <span>{countdown.seconds}</span>
            <span>Seconds</span>
          </Box>
        </Box>
      )}
      {diff !== undefined && (
        <Confetti active={showConfetti} config={{ colors: colors }} />
      )}
      {releaseMessage && (
        <Box className={style.countdownRelease}>
          <Typography variant="labelLarge">{releaseMessage}</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default SlinkyCountDown;
