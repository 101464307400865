import * as React from "react";

import {
  ButtonWrapper,
  ButtonWrapperProps,
} from "@anthology/shared/src/components";
import { ArrowBack, FacebookRounded, Google } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Grow,
  IconButton,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { BsSnapchat } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { useCssBreakpoint } from "../../../hooks";
import PrivacyPolicy from "../../privacy-policy/PrivacyPolicy";
import { SlinkyDialog } from "../slinkyDialog/SlinkyDialog";

export type SlinkyManagePermissionsProps = {
  open: boolean;
  handleSavePermissions: (accept: boolean) => void;
  artistNames?: string;
};

export const dialogButtonProps: Partial<ButtonWrapperProps> = {
  size: "large",
  sx: { flex: 1, borderRadius: 2 },
};

export function SlinkyManagePermissions({
  open,
  handleSavePermissions,
  artistNames,
}: SlinkyManagePermissionsProps) {
  const isMobileLayout = useCssBreakpoint("sm");
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = React.useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = React.useState(false);
  const [advertisingToggle, setAdvertisingToggle] = React.useState(true);
  const [showManagePermissions, setShowManagePermissions] =
    React.useState(false);

  return (
    <SlinkyDialog
      open={open}
      TransitionComponent={Grow}
      title="Manage Permissions"
    >
      <Box p={2}>
        <Typography variant="h5" mb={3}>
          {showManagePermissions ? "Manage Permissions" : "Privacy notice"}
        </Typography>
        <Typography component={Box} variant="bodyMedium">
          {artistNames ? (
            <>
              On behalf of{" "}
              <Typography display="inline" textTransform="uppercase">
                {artistNames}
              </Typography>{" "}
              we{" "}
            </>
          ) : (
            "We"
          )}{" "}
          request permission to use cookies to identify visitors. Your data will
          be handled according to your permissions and deleted 6 months after
          your last visit. Third parties may also place cookies for personalized
          advertising. Functional cookies, which do not contain personal
          information, are used to improve user experience and performance.{" "}
          <b>
            By accepting, you enhance your browsing experience and support{" "}
            {artistNames} in reaching more fans and creating the music you love.
            ❤️
          </b>
        </Typography>
        {showManagePermissions && (
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                onChange={(e) => setAdvertisingToggle(e.target.checked)}
              />
            }
            label="Advertising tracking"
            value={advertisingToggle}
            sx={{ mt: 3 }}
          />
        )}
        <DialogActions
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "12px",
            padding: 0,
            mt: 5,
          }}
        >
          <Stack
            direction={isMobileLayout ? "column" : "row"}
            gap={2}
            flexWrap={"wrap"}
            width={"100%"}
          >
            {!showManagePermissions ? (
              <>
                <ButtonWrapper
                  variant="contained"
                  onClick={() => handleSavePermissions(true)}
                  {...dialogButtonProps}
                >
                  Accept
                </ButtonWrapper>

                <ButtonWrapper
                  variant="outlined"
                  onClick={() => setShowManagePermissions(true)}
                  {...dialogButtonProps}
                >
                  Manage Permissions
                </ButtonWrapper>
              </>
            ) : (
              <ButtonWrapper
                variant="contained"
                onClick={() => handleSavePermissions(advertisingToggle)}
                {...dialogButtonProps}
              >
                Save
              </ButtonWrapper>
            )}
          </Stack>

          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"center"}
            mt={5}
            width={"100%"}
          >
            <Link
              underline="none"
              component="button"
              variant="button"
              onClick={() => setIsDetailsDialogOpen(true)}
              sx={{ fontSize: 14 }}
            >
              Details
            </Link>
            <Typography sx={{ fontSize: 14 }}>|</Typography>
            <Link
              underline="none"
              component="button"
              variant="button"
              onClick={() => setIsPrivacyPolicyOpen(true)}
              sx={{ fontSize: 14 }}
            >
              Privacy Policy
            </Link>
          </Stack>
        </DialogActions>
        <AdTrackingDetailsDialog
          open={isDetailsDialogOpen}
          onClose={() => setIsDetailsDialogOpen(false)}
        />
        <PrivacyPolicyDialog
          open={isPrivacyPolicyOpen}
          onClose={() => setIsPrivacyPolicyOpen(false)}
        />
      </Box>
    </SlinkyDialog>
  );
}

const PolicyLink = ({ link }: { link: string }) => {
  return (
    <Link
      target="_blank"
      rel="noopener"
      href={link}
      underline="none"
      variant="button"
    >
      View Privacy Policy
    </Link>
  );
};

const AdTrackingDetailsDialog = ({ open, onClose }: any) => {
  return (
    <Dialog open={open} hideBackdrop TransitionComponent={Grow}>
      <Box p={2}>
        <Stack direction="row" gap={2} alignItems="center" mb={3}>
          <IconButton onClick={onClose} sx={{ padding: 0 }}>
            <ArrowBack fontSize="large" />
          </IconButton>{" "}
          Advertisement Tracking
        </Stack>
        <DialogContentText>
          <Box>
            To better personalise and deliver tailored advertising to users, we
            worked with 3rd party providers and systems. These 3rd parties may
            place cookies containing user identifiers.
          </Box>
          <Stack marginTop={3} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FacebookRounded />
              <Typography>Facebook</Typography>
            </Stack>
            <div>
              Facebook helps us gather information about visitors on our
              websites. This information helps us ensure that our content
              reaches its intended audience.
            </div>
            <PolicyLink link="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" />
          </Stack>
          <Stack marginTop={3} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FaXTwitter />
              <Typography>X</Typography>
            </Stack>
            <div>
              X Page view helps us gather information about conversions made on
              our websites. This information is used to help creators improve
              their campaigns.
            </div>
            <PolicyLink link="https://help.twitter.com/en/rules-and-policies/update-privacy-policy" />
          </Stack>
          <Stack marginTop={3} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <BsSnapchat size={24} />
              <Typography>Snapchat</Typography>
            </Stack>
            <div>
              Snapchat helps us gather information on the effectiveness of
              advertising campaigns. This information is used to give creators
              more control over the campaigns.
            </div>
            <PolicyLink link="https://www.snap.com/en-US/privacy/privacy-policy" />
          </Stack>
          <Stack marginTop={3} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Google />
              <Typography>Google</Typography>
            </Stack>
            <div>
              Google helps us gather information on the effectiveness of
              advertising campaigns. This information is used to give creators
              more control over the campaigns.
            </div>
            <PolicyLink link="https://policies.google.com/technologies/ads?hl=en-US" />
          </Stack>
        </DialogContentText>
      </Box>
    </Dialog>
  );
};

const PrivacyPolicyDialog = ({ open, onClose }: any) => {
  return (
    <Dialog
      open={open}
      hideBackdrop
      TransitionComponent={Grow}
      maxWidth="xl"
      fullWidth
    >
      <Stack alignItems={"flex-start"}>
        <IconButton onClick={onClose}>
          <ArrowBack fontSize="large" />
        </IconButton>{" "}
        <PrivacyPolicy />
      </Stack>
    </Dialog>
  );
};
