'use client';
import { datadogLogs } from '@datadog/browser-logs';
import * as version from '../public/version.json';

datadogLogs.init({
  clientToken: 'pub88b43ca8c29a778c53c962d63dec916b',
  site: 'datadoghq.eu',
  service: 'slinky-frontend',
  env: 'prod',
  version: version.autoGitHash,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}