export const SlinkyUrlParams = {
  id: "id",
  code: "code",
  spotifyCode: "spotifyCode",
  error: "error",
  trackingLink: "tl",
  ps: "ps",
  c: "c",
};

export const SlinkyLocalStorageNames = {
  spotifyPresave: "spotifyPresave",
  permissions: "euconsent-v2",
  preferences: "slinkyPreferences",
  urlExtension: "urlExtension",
  imageUrl: "imageUrl",
  correlationId: "correlationId",
};

export const spotifyClientId = "bb5c4e2c52b54889b0c64e1338483f60";
