import { Dialog, styled } from "@mui/material";

export const SlinkyDialog = styled(Dialog)(({}) => ({
  "--mui-palette-background-default": "#000000",
  "--mui-palette-background-paper": "#222222",
  "--mui-palette-text-primary": "#ffffff",
  "--mui-palette-text-secondary": "#cccccc",
  "--mui-palette-text-disabled": "#666666",
  "--mui-palette-primary-main": "#0fb4e2",
  "--mui-palette-primary-contrastText": "#333333",
  "--mui-palette-action-active": "#cccccc",
}));
