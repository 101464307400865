import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { MarkDownView } from "..";
import { staticPath } from "../../constants/urls";

const PrivacyPolicy = () => {
  const [markdownSrc, setMarkdownSrc] = useState("");
  useEffect(() => {
    fetch(`${staticPath}legal/privacyPolicyMarkdown.md`).then((res: any) => {
      res.text().then((text: any) => {
        setMarkdownSrc(text);
      });
    });
  }, []);
  return markdownSrc ? (
    <Box p={3}>
      <MarkDownView source={markdownSrc} />
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default PrivacyPolicy;
