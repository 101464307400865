import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { BsX } from "react-icons/bs";
import { ButtonWrapper, CircleIconButton, Flag } from "../..";
import { SelectListVm } from "../../../api/slinkyApi";
import { SlinkyDialog } from "../slinkyDialog/SlinkyDialog";
import { dialogButtonProps } from "../slinkyManagePreferences/SlinkyManagePermissions";
export interface TerritoryLanguage {
  languageId: number;
  territoryId: number;
}

const SlinkyTerritoryLanguageDialog = ({
  open,
  onClose,
  territoryId,
  languageId,
  territories = [],
  languages = [],
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  territoryId: number;
  languageId: number;
  territories?: SelectListVm[];
  languages?: SelectListVm[];
  onSave: (data: TerritoryLanguage) => void;
}) => {
  // Form
  const { control, handleSubmit, reset } = useForm<TerritoryLanguage>();

  // Close

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <SlinkyDialog
      open={open}
      onClose={handleClose}
      title="Where are you?"
      fullWidth
      maxWidth="sm"
    >
      <Stack gap={4} overflow={"auto"} p={3}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="sub2">Location / Language</Typography>
          <CircleIconButton onClick={() => handleClose()}>
            <BsX />
          </CircleIconButton>
        </Stack>
        <form onSubmit={handleSubmit(onSave)}>
          <Stack gap={5}>
            <Stack gap={2}>
              <FormControl fullWidth>
                <InputLabel id="country-territory">
                  Country/Territory
                </InputLabel>
                <Controller
                  name={"territoryId"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="territory-label"
                      label="Country/Territor"
                      fullWidth
                      placeholder="Select territory..."
                      defaultValue={territoryId}
                    >
                      {territories?.map((territory) => (
                        <MenuItem key={territory.id} value={territory.id}>
                          <Stack direction={"row"} gap={1}>
                            <Flag
                              countryCode={territory.isoCode || ""}
                              height={20}
                            />
                            {territory.text}
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <Controller
                name={"languageId"}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Preferred Language"
                    fullWidth
                    defaultValue={languageId}
                  >
                    {languages?.map((language) => (
                      <MenuItem key={language.id} value={language.id}>
                        {language.text}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
            <ButtonWrapper
              variant="contained"
              type="submit"
              {...dialogButtonProps}
            >
              Save
            </ButtonWrapper>
          </Stack>
        </form>
      </Stack>
    </SlinkyDialog>
  );
};

export default SlinkyTerritoryLanguageDialog;
