import { Palette } from "@mui/material";
import { usePalette } from "color-thief-react";
import { getLuminance } from "color2k";

export const useImageColours = (
  imageUrl: string,
  themeProps?: {
    isDark: boolean;
    palette: Palette;
  },
  unique?: boolean
) => {
  const { data } = usePalette(imageUrl, 12, "hex", {
    crossOrigin: "anonymous",
  });
  const { isDark, palette } = themeProps || { isDark: true };
  const primaryColour =
    data?.find((colour) => {
      const luminance = getLuminance(colour);
      // console.log(`%c ${colour}: ${luminance} `, `color: ${colour}`);
      if (isDark) {
        return luminance > 0.19 && luminance < 0.8;
      } else {
        return luminance > 0.1 && luminance < 0.3;
      }
    }) || palette?.primary.main;
  const backgroundColourList = data?.filter((colour) => {
    const luminance = getLuminance(colour);
    if (colour === primaryColour && unique) return false;
    if (isDark) {
      return luminance > 0.05 && luminance < 0.3;
    } else {
      return luminance > 0.3 && luminance < 0.6;
    }
  });
  const backgroundColour =
    (backgroundColourList && backgroundColourList[0]) ||
    palette?.background.default;
  return { primaryColour, backgroundColour, backgroundColourList };
};
