import style from "../RenderSlinky.module.scss";

const SlinkySpotifyPlayer = ({
  url,
  onClick,
}: {
  url: string;
  onClick: () => void;
}) => {
  return (
    <iframe
      height={80}
      className={style.audioPlayerIframe}
      id="spotify-iframe"
      src={url}
      allow="encrypted-media"
      // allowTransparency={true} causing console errorrs
      title={"spotify iframe"}
      onClick={onClick}
      style={{ borderRadius: "12px", border: "none" }}
      frameBorder="0"
    ></iframe>
  );
};

export default SlinkySpotifyPlayer;
