import { isAfter } from "date-fns";
import {
  CallToActionVm,
  LinkTerritoryGroupVm,
  LinkVm,
} from "../../api/slinkyApi";
import { getLiveTime, isPlatformPresave } from "./slinkyHelpers";
//import { SlinkyPresavePlatform } from "./slinkyPreSaveDialog/SlinkyPreSaveDialog";

export interface SlinkyCta {
  ctaId?: number;
  text: string;
  url?: string;
  presavePlatform?: any; // SlinkyPresavePlatform;
  destinationFormatId?: string;
  slinkyDestinationLinkTerritorieId?: number;
}

export const useSlinkyTerritoryCta = (date?: Date, languageId?: number) => {
  const userLanguageId = languageId || 10;
  const getCTA = (ctas?: CallToActionVm[] | null) => {
    return (
      ctas && (ctas.find((x) => x.languageLinkId === userLanguageId) ?? ctas[0])
    );
  };

  const getSlinkyTerritoryGroup = (link: LinkVm, territoryId?: number) => {
    const groups = link!.territoryGroups;
    const defaultGroup = groups![0];
    if (territoryId) {
      const matchedGroups = groups?.filter((group) =>
        group.territoryIds?.includes(territoryId)
      );
      const smallestGroups = matchedGroups?.sort((a, b) => {
        if (!a.territoryIds || !b.territoryIds) return 0;
        return b.territoryIds.length - a.territoryIds.length;
      });
      return (smallestGroups && smallestGroups[0]) || defaultGroup;
    } else {
      return defaultGroup;
    }
  };

  const isSlinkyLinkTerritoryLive = (territoryGroup: LinkTerritoryGroupVm) => {
    if (!territoryGroup?.releaseDateTime) return true;
    const viewDate = date || new Date();
    const applyLiveDateTime = getLiveTime(
      territoryGroup.releaseDateTime,
      territoryGroup.isUtcdateTime
    );
    return isAfter(viewDate, applyLiveDateTime);
  };

  const isSlinkyLinkPreOrderReady = (territoryGroup: LinkTerritoryGroupVm) => {
    if (!territoryGroup?.preOrderDateTime) return true;
    const viewDate = date || new Date();
    const applyLiveDateTime = getLiveTime(
      territoryGroup.preOrderDateTime,
      territoryGroup.isUtcdateTime
    );
    return isAfter(viewDate, applyLiveDateTime);
  };

  const getSlinkyCta = (link: LinkVm, territoryId?: number): SlinkyCta => {
    const territoryGroup = getSlinkyTerritoryGroup(link, territoryId);
    const showPreReleases = isSlinkyLinkPreOrderReady(territoryGroup);
    if (!showPreReleases)
      return {
        text: "",
        url: "",
        presavePlatform: undefined,
        destinationFormatId: undefined,
        ctaId: undefined,
      };
    const isLive = isSlinkyLinkTerritoryLive(territoryGroup);
    const presavePlatform =
      (!isLive && isPlatformPresave(link.platformId) && link.platformId) ||
      undefined;
    const preReleaseCta = !isLive && getCTA(territoryGroup.preReleaseCtas);
    const postReleaseCta = getCTA(territoryGroup.postReleaseCtas);
    const cta = preReleaseCta || postReleaseCta;
    const url =
      (isLive
        ? territoryGroup?.postLinkIdentifierOrUrl
        : territoryGroup.preLinkIdentifierOrUrl) || "";

    return {
      text: cta?.callToAction || "Open",
      url: url,
      presavePlatform: presavePlatform,
      destinationFormatId: territoryGroup?.destinationFormatId || "",
      ctaId: cta?.callToActionGroupLinkId || undefined,
      slinkyDestinationLinkTerritorieId:
        territoryGroup?.slinkyDestinationLinkTerritorieId || 0,
    };
  };

  return {
    getSlinkyCta: getSlinkyCta,
    isSlinkyLinkTerritoryLive: isSlinkyLinkTerritoryLive,
  };
};
