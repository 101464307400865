import { SxProps } from "@mui/material";
import { isValid as isValidDate, parseISO } from "date-fns";
import { LinkVm, LookAndFeelVm, SlinkyVm } from "../../api/slinkyApi";
import { Platforms } from "../../api/types/autoEnum";
import { hexToRgba } from "../../utils/colours";
import { SlinkyLocalStorageNames } from "./consts";
import { TerritoryLanguage } from "./slinkyTerritoryLanguageDialog/SlinkyTerritoryLanguageDialog";

export const getLinksToShow = (slinky: SlinkyVm, territoryId: number) => {
  const getTerritorySequence = (link: LinkVm) =>
    (territoryId &&
      link.customTerritorySequences!.find(
        (territorySequence) => territorySequence.territoryLinkId === territoryId
      )) ||
    null;

  const getSequenceValue = (link: LinkVm) => {
    const sequence = getTerritorySequence(link)?.sequence;
    return sequence || link.sequence || 0;
  };

  const getIsLinkVisible = (link: LinkVm) => {
    const isTerritoryExcluded =
      territoryId &&
      !link.territoryGroups?.some((group) =>
        group.territoryIds?.includes(territoryId)
      );
    if (isTerritoryExcluded) return false;
    const territorySequence = getTerritorySequence(link);
    if (territorySequence) {
      return !territorySequence.isHidden;
    } else {
      return link.isVisible;
    }
  };

  return slinky?.links
    ? slinky?.links
        .filter((link) => getIsLinkVisible(link))
        .sort((a, b) => getSequenceValue(a) - getSequenceValue(b))
    : [];
};

export const getSlinkyFanPreferences = () => {
  if (typeof window == "undefined") return {} as any;
  const storedPreferencesString = localStorage.getItem(
    SlinkyLocalStorageNames.preferences
  );
  const storedPreferences =
    storedPreferencesString && JSON.parse(storedPreferencesString);
  return {
    territoryId: parseInt(storedPreferences?.territoryId),
    languageId: parseInt(storedPreferences?.languageId),
  } as TerritoryLanguage;
};

export const getRollingTime = (liveDateTime: Date) => {
  const year = liveDateTime.getUTCFullYear();
  const month = liveDateTime.getUTCMonth();
  const day = liveDateTime.getUTCDate();
  // Show at midnight for all users
  return new Date(year, month, day, 0, 0, 0);
};

export const getLiveTime = (liveDateTime: string, isUtc?: boolean | null) => {
  const releaseDateTime = new Date(liveDateTime);
  const rollingDateTime = getRollingTime(releaseDateTime);
  return isUtc ? releaseDateTime : rollingDateTime;
};

export const isPlatformPresave = (platformId?: number | null) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isPresaveTest = urlParams.get("allPresaveTest") !== null;
  return (
    isPresaveTest ||
    (platformId &&
      [Platforms.Spotify, Platforms.Apple_Music].includes(platformId))
  );
};

export const getSlinkyBgSxProps = ({
  backgroundBlur,
  backgroundColor,
  backgroundImageOpacity,
}: Partial<LookAndFeelVm>): SxProps => {
  return {
    backdropFilter: `blur(${backgroundBlur || 0}px)`,
    backgroundColor: hexToRgba(
      backgroundColor || "#000000",
      backgroundImageOpacity || 0
    ),
  };
};

export const checkPrivateMode = async () => {
  try {
    await navigator.storage.estimate();
    return false; // Not in private mode
  } catch (error) {
    return true; // Likely in private mode
  }
};

export const getLinkTerritoryDate = (link: LinkVm, territoryId: number) => {
  const territoryGroup =
    link.territoryGroups?.find((group) =>
      group.territoryIds?.includes(territoryId)
    ) || link.territoryGroups?.[0];
  return (
    territoryGroup?.releaseDateTime &&
    isValidDate(parseISO(territoryGroup?.releaseDateTime)) &&
    getLiveTime(
      territoryGroup.releaseDateTime,
      territoryGroup.isUtcdateTime
    ).toISOString()
  );
};

type DateLinksType = "physical" | "digital" | "noformat";
const getLinksTerritoryDate = (
  links: LinkVm[],
  territoryId: number,
  type: DateLinksType
) => {
  const releaseDates =
    links && links.map((link) => getLinkTerritoryDate(link, territoryId));
  const validReleaseDates = releaseDates.filter(
    (date): date is string => !!date
  );

  if (validReleaseDates.length > 0) {
    if (type === "digital") {
      return Math.max(...validReleaseDates.map((d) => new Date(d).getTime()));
    } else if (type === "physical") {
      return Math.min(...validReleaseDates.map((d) => new Date(d).getTime()));
    } else if (type === "noformat") {
      const dateCounts = validReleaseDates.reduce(
        (acc: Record<string, number>, date) => {
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        },
        {}
      );
      const mostCommonDate = Object.keys(dateCounts).reduce((a, b) =>
        dateCounts[a] > dateCounts[b] ? a : b
      );
      return mostCommonDate;
    }
  } else {
    return null;
  }

  return validReleaseDates.length > 0
    ? new Date(Math.min(...validReleaseDates.map((d) => new Date(d).getTime())))
    : null;
};

export const getSlinkyReleaseDate = (slinky: SlinkyVm, territoryId: number) => {
  if (!slinky?.links) return null;
  let dateLinks = slinky.links;
  let dateLinksType: DateLinksType = "noformat";
  const digitalLinks = slinky.links.filter((link) => {
    const relatedFormat = slinky.relatedFormats?.find(
      (format) => format.formatId === link.formatId
    );
    return relatedFormat?.isDigital;
  });
  const physicalLinks = slinky.links.filter((link) => {
    const relatedFormat = slinky.relatedFormats?.find(
      (format) => format.formatId === link.formatId
    );
    return !relatedFormat?.isDigital;
  });
  if (digitalLinks.length > 0) {
    dateLinks = digitalLinks;
    dateLinksType = "digital";
  } else if (physicalLinks.length > 0) {
    dateLinks = physicalLinks;
    dateLinksType = "physical";
  }
  return getLinksTerritoryDate(dateLinks, territoryId, dateLinksType);
};

export const extractFormatYouTubeUrl = (url: string) => {
  // Handle just the ID case
  if (/^[a-zA-Z0-9-_]{11}$/.test(url)) {
    return url;
  }

  const patterns: RegExp[] = [
    /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:\.be\/|be\.com\/(?:watch\?v=|v\/|embed\/|user\/(?:[\w#]+\/)+))([^#&?]*).*/,
    /(?:https?:\/\/)?(?:www\.|m\.)?youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)([^#&?]*).*/,
    /(?:https?:\/\/)?youtu\.be\/([^#&?]*).*/,
    /(?:https?:\/\/)?(?:www\.|m\.)?youtube\.com\/(?:channel|attribution_link|c\/[\w-]+\/videos)(?:\S+)?(?:\?v=|\&v=)([^#&?]*).*/,
    /(?:https?:\/\/)?(?:www\.|m\.)?youtube\.com\/playlist\?(?:.+&)?list=([^#&?]*).*/,
  ];

  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      if (/^[a-zA-Z0-9-_]{11}$/.test(match[1])) {
        return match[1];
      }
    }
  }

  // If we get here, we couldn't find a valid YouTube ID
  return null;
};

export const formatYoutubeUrl = (
  youTubeUrl: string,
  isEdit?: boolean
): string => {
  let id = extractFormatYouTubeUrl(youTubeUrl);
  if (!id) id = youTubeUrl;
  return isEdit ? id : `https://www.youtube.com/embed/${id}`;
  // TODO: Strip out start time and update corresponding field
};

export const formatSpotifyUrl = (
  spotifyUrl: string,
  isEdit?: boolean
): string => {
  let id;
  const types = ["album", "track", "episode", "playlist"];
  const seperatorChars = ["/", ":"];
  const seperators = types.reduce((acc: string[], type: string) => {
    return [...acc, ...seperatorChars.map((char) => `${type}${char}`)];
  }, []);
  seperators.forEach((item) => {
    if (spotifyUrl?.indexOf(item) > -1) {
      const urlArray = spotifyUrl.split(item);
      id = item.replace(":", "/") + urlArray[urlArray.length - 1];
    }
  });
  if (!id) id = spotifyUrl;
  return isEdit ? id : `https://open.spotify.com/embed/${id}`;
};

export enum MobileDeviceOs {
  Unknown,
  Android,
  iOS,
}
export const getMobileDeviceOs = () => {
  if (typeof window !== "undefined") {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return MobileDeviceOs.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return MobileDeviceOs.iOS;
    }
  }

  return MobileDeviceOs.Unknown;
};
