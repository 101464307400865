/*!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.*/

const DolbyImmersiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="white"
    style={{ filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))" }}
  >
    <path d="M0 85.3L0 426.7l512 0 0-341.3L0 85.3zM213.3 256c0 47.1-38.2 85.3-85.3 85.3l-42.7 0 0-170.7 42.7 0c47.1 0 85.3 38.2 85.3 85.3zM384 341.3c-47.1 0-85.3-38.2-85.3-85.3s38.2-85.3 85.3-85.3l42.7 0 0 170.7-42.7 0z" />
  </svg>
);

export default DolbyImmersiveIcon;
