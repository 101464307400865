import { CSSProperties } from "react";
import { flagPath } from "../../constants/urls";
import ImageWrapper from "../image-wrapper/ImageWrapper";

const Flag = ({
  height = 40,
  countryCode,
  variant = "circle",
  style,
}: {
  height?: number;
  countryCode: string;
  variant?: "circle" | "square" | "natural";
  style?: CSSProperties;
}) => {
  const folderName =
    variant === "square" ? "4/4" : variant === "natural" ? "4/3" : "circle";
  return (
    <ImageWrapper
      src={`${flagPath}${folderName}/${countryCode?.toLocaleLowerCase()}.svg`}
      height={height}
      style={style}
    />
  );
};

export default Flag;
