//import gtag from "ga-gtag";
import _ from "lodash";
import TagManager from "react-gtm-module";
import { PixelVm } from "../../api/slinkyApi";
import { PixelTypes } from "../../api/types/autoEnum";

declare global {
  interface Window {
    scCookie: any;
    scPixelValues: {
      [key: string]: {
        loader: () => void;
        Ids: string[];
        IsLoaded?: boolean;
      };
    };
    viewModelCookie: string;
    viewModelPixelValues: string;
    viewModelCookieName: string;
    dataLayer: any[];
    gtag: (...args: any[]) => void;
    snaptr: (...args: any[]) => void;
    ttq: any;
    twq: (...args: any[]) => void;
    fbq: (...args: any[]) => void;
    rdt: (...args: any[]) => void;
    isUnavailable: boolean;
    hasRedirectUrl: boolean;
    artistName: string;
    labelName: string;
    MusicKitInstance: any;
    MusicKit: any;
  }
}

const googleLoder = (pixelIds: string[]) => {
  // Ensure dataLayer and gtag exist
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function () {
      window.dataLayer.push(arguments);
    };

  window.gtag("js", new Date());

  // Configure each pixel ID
  pixelIds.forEach((id) => {
    window.gtag("config", id);
  });
};

const gtmLoader = (pixelIds: string[]) => {
  pixelIds.forEach((pixelId) => {
    const tagManagerArgs = {
      gtmId: pixelId,
      // dataLayerName: GoogleCustomEvent[PixelEventType.PageView],
      dataLayer: {
        event: CustomEvent[PixelEventType.PageView],
        pagePath: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  });
};

const snapchatLoader = (pixelIds: string[]) => {
  (function (win, doc, sdk_url) {
    if (win.snaptr as any) return;
    var tr: any = (win.snaptr = function () {
      tr.handleRequest
        ? tr.handleRequest.apply(tr, arguments)
        : tr.queue.push(arguments);
    });
    tr.queue = [];
    var s = "script";
    var new_script_section = doc.createElement(s) as any;
    new_script_section.async = !0;
    new_script_section.src = sdk_url;
    var insert_pos = doc.getElementsByTagName(s)[0];
    insert_pos.parentNode?.insertBefore(new_script_section, insert_pos);
  })(window, document, "https://sc-static.net/scevent.min.js");

  pixelIds.forEach(function (id) {
    window.snaptr("init", id);
  });

  window.snaptr("track", "PAGE_VIEW");
};

const tiktokLoader = (pixelIds: string[]) =>
  !(
    function (w: any, _d: any, t: any) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
      ]),
        (ttq.setAndDefer = function (t: any, e: any) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t: any) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e: any, n: any) {
          var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          var o = document.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.src = i + "?sdkid=" + e + "&lib=" + t);
          var a = document.getElementsByTagName("script")[0];
          a.parentNode?.insertBefore(o, a);
        });

      pixelIds.forEach(function (id) {
        ttq.load(id);
      });
      ttq.page();
    } as any
  )(window, document, "ttq");

const xLoader = (pixelIds: string[]) => {
  !(
    function (e: any, t: any, n: any, s: any, u: any, a: any) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = "1.1"),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = "//static.ads-twitter.com/uwt.js"),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    } as any
  )(window, document, "script");

  pixelIds.forEach(function (id) {
    window.twq("init", id);
  });
  window.twq("track", "PageView");
};

const metaLoader = (pixelIds: string[]) => {
  !(
    function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    } as any
  )(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );

  pixelIds.forEach(function (id) {
    window.fbq("init", id);
  });
  facebookCustomEvent(PixelEventType.PageView);
};

const redditLoader = (pixelIds: string[]) => {
  !(
    function (w: any, d: any) {
      if (!w.rdt) {
        var p = (w.rdt = function () {
          p.sendEvent
            ? p.sendEvent.apply(p, arguments)
            : p.callQueue.push(arguments);
        }) as any;
        p.callQueue = [];
        var t = d.createElement("script");
        (t.src = "https://www.redditstatic.com/ads/pixel.js"), (t.async = !0);
        var s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
      }
    } as any
  )(window, document);
  pixelIds.forEach(function (id) {
    window.rdt("init", id);
  });
  window.rdt("track", "PageVisit");
};

const PixelLoader: any = {
  [PixelTypes.Google_Analytics_GA4_ID]: googleLoder,
  [PixelTypes.Meta_Pixel_Id]: metaLoader,
  [PixelTypes.Reddit_Pixel_Id]: redditLoader,
  [PixelTypes.Snapchat_Pixel_Id]: snapchatLoader,
  [PixelTypes.TikTok_Pixel_Id]: tiktokLoader,
  [PixelTypes.X_Pixel_Id]: xLoader,
  [PixelTypes.Google_Tag]: gtmLoader,
};

const AbsolutePixel: PixelVm = {
  pixelId: -1,
  pixelTypeLinkId: PixelTypes.Meta_Pixel_Id,
  pixelIdentifier: "360057147521249",

  // slinkyToPixelId: 0,
  // pixelIdentifier: "",
  // slinkyAccountLinkId: 0,
  // labelLinkId: 0,
  // contributorLinkId: 0,
  // anthologyResourceTypeLinkId: 0,
  // isAutoAdd: false,
};

const MerlinPixelTikTok: PixelVm = {
  pixelId: -1,
  pixelTypeLinkId: PixelTypes.TikTok_Pixel_Id,
  pixelIdentifier: "CQL0VIJC77U4RGRFNHU0",
};

const AbsolutePixelTikTok: PixelVm = {
  pixelId: -1,
  pixelTypeLinkId: PixelTypes.TikTok_Pixel_Id,
  pixelIdentifier: "CPTB8LJC77UEB9QOCIL0",
};

export const handlePixels = (pixels: PixelVm[]) => {
  const allPixels: PixelVm[] = [
    ...pixels,
    AbsolutePixel,
    MerlinPixelTikTok,
    AbsolutePixelTikTok,
  ];
  const pixelGroups = _.groupBy(allPixels, "pixelTypeLinkId");
  Object.entries(pixelGroups).forEach(([key, value]) => {
    const pixelIds = value.map((p) => p.pixelIdentifier);
    if (PixelLoader[key]) {
      PixelLoader[key](pixelIds);
    }
  });
};

export enum PixelEventType {
  PageView,
  ViewContent,
  Click,
  SocialsClick,
  Lead,
  AudioPreview,
  VideoPreview,
  PreSaveStart,
  PreSaveEnd,
  PreSaveCancelled,
  PreSaveError,
}

const DestinationPixelEvents = [
  PixelEventType.PreSaveStart,
  PixelEventType.PreSaveEnd,
];

// export enum PixelEventGroup {
//   PageLoad,
//   DestinationClick,
//   PreSaveComplete
// }

type PixelObj = {
  [key: string]: string;
};

// type PixelGroup = {
//   [key: string]: string[]
// }

const CustomEvent: PixelObj = {
  [PixelEventType.PageView]: "PageView",
  [PixelEventType.ViewContent]: "ViewContent",
  [PixelEventType.PreSaveStart]: "PreSaveInitiated",
  [PixelEventType.PreSaveEnd]: "PreSaveCompleted",
  [PixelEventType.Click]: "Click",
  [PixelEventType.PreSaveCancelled]: "PreSaveCancelled",
  [PixelEventType.Lead]: "Lead",
  [PixelEventType.SocialsClick]: "SocialsClicks",
  [PixelEventType.VideoPreview]: "VideoPreview",
  [PixelEventType.AudioPreview]: "AudioPreview",
};

export const handlePixelEvent = (
  type: PixelEventType,
  retailerName?: string,
  retailerId?: number
) => {
  facebookCustomEvent(type, retailerName);
  gtmCustomEvent(type, retailerName);
  tikTokCustomEvent(type, retailerName, retailerId);
};

const facebookCustomEvent = (type: PixelEventType, retailerName?: string) => {
  const fbq = window.fbq as any;
  const trackEvent = (appendRetailer?: boolean) => {
    const eventBaseName = CustomEvent[type];
    if (!eventBaseName) return;
    const eventName = appendRetailer
      ? `${eventBaseName}${retailerName?.replaceAll(" ", "")}`
      : eventBaseName;
    fbq("track", eventName, {
      content_ids: retailerName ? [retailerName] : undefined,
      content_type: "product",
      content_name: retailerName,
      value: 1.0,
      currency: "GBP",
    });
  };
  if (fbq) {
    trackEvent();
    if (retailerName && DestinationPixelEvents.includes(type)) {
      trackEvent(true);
    }
  }
};

const gtmCustomEvent = (type: PixelEventType, retailerName?: string) => {
  if (TagManager.dataLayer) {
    TagManager.dataLayer({
      dataLayer: {
        event: CustomEvent[type],
        event_label: retailerName,
        value: 1,
      },
    });
  }
};

const tikTokCustomEvent = (
  type: PixelEventType,
  retailerName?: string,
  retailerId?: number
) => {
  if (window.ttq) {
    window.ttq.track(CustomEvent[type], {
      destinationName: retailerName,
      content_id: retailerId,
    });
  }
};
