import { toRgba } from "color2k";

const colourToHex = (colour: string) => `#${colour}`;

const hexToRgb = (hex: string) => {
  const rgba = toRgba(hex);
  return rgba.replace("rgba", "rgb").replace(",1)", ")");
};

const getRgbValue = (rgb: string) =>
  rgb.replace("rgb(", "").replace(")", "").replaceAll(",", "").trim();

const hexToRgba = (hex: string, opacity: number) => {
  if (hex.length === 7 && hex.indexOf("#") === 0) {
    const rgb = hexToRgb(hex);
    const rgbColourString = rgb.replace("rgb", "").replace("1)", "");
    return `rgba${rgbColourString}${opacity})`;
  }
  return "";
};

export { colourToHex, getRgbValue, hexToRgb, hexToRgba };
