import {
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import { forwardRef } from "react";
import { useCssBreakpoint } from "../../hooks";

export type ButtonWrapperProps = ButtonProps;

const getContainerHeight = (size: "large" | "medium" | "small") => {
  switch (size) {
    case "large":
      return "56px";
    case "medium":
      return "43px";
    case "small":
      return "35px";
  }
};

const getPadding = (size: "large" | "medium" | "small") => {
  switch (size) {
    case "large":
      return "20px 24px";
    case "medium":
      return "13px 24px";
    case "small":
      return "9px 24px";
  }
};

const StyledButton = styled(Button)(({ theme, size = "medium" }) => ({
  height: getContainerHeight(size),
  padding: getPadding(size),
  borderRadius: "30px",
  fontSize: theme.typography.button.fontSize,
}));

// export const ButtonWrapper = ({
//   isLoading,
//   ...props
// }: ButtonWrapperProps & { isLoading?: boolean; path?: string }) => {
//   // const navigate = useNavigate();
//   // if (props.path && !props.onClick) {
//   //   props.onClick = () => navigate(props.path!);
//   // }
//   return (
//     <StyledButton {...props}>
//       {isLoading ? <CircularProgress size={20} /> : props.children}
//     </StyledButton>
//   );
// };

export const ButtonWrapper = forwardRef<
  HTMLButtonElement,
  ButtonWrapperProps & {
    isLoading?: boolean;
    loadingText?: string;
    path?: string;
    tooltipTitle?: string;
    mobileFullWidth?: boolean;
  }
>(
  (
    { isLoading, loadingText, tooltipTitle, mobileFullWidth, ...props },
    ref
  ) => {
    const isMobileLayout = useCssBreakpoint("sm");
    const styledButton = (
      <StyledButton
        ref={ref}
        {...props}
        fullWidth={props.fullWidth || (mobileFullWidth && isMobileLayout)}
      >
        {isLoading ? (
          <Stack direction="row" gap={1} alignItems={"center"}>
            <CircularProgress size={20} />
            {loadingText}
          </Stack>
        ) : (
          props.children
        )}
      </StyledButton>
    );
    return tooltipTitle ? (
      <Tooltip title={tooltipTitle}>{styledButton}</Tooltip>
    ) : (
      styledButton
    );
  }
);
